$(document).ready(function () {

  initLangsDropdown();
  initMatchHeight();

});

function initLangsDropdown() {
  var clickItem = '.header .languages .current';
  $(clickItem).on('click',function() {
    $(this).parent().stop(true,true).toggleClass('show');
		$(this).parent().find('.dropdown').stop(true,true).slideToggle();
	});
  $(document).on('click', function(e) {
    if ($(e.target).closest('.languages').length || $(e.target).hasClass('languages')) return;
    $('.header .languages').removeClass('show');
    $('.header .languages .dropdown').stop(true,true).slideUp();
  });
}

function initMatchHeight() {
  $('.height').matchHeight();
}
